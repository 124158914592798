exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-cennik-tsx": () => import("./../../../src/pages/cennik/Cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-cennik-tsx" */),
  "component---src-pages-cennik-components-info-box-tsx": () => import("./../../../src/pages/cennik/components/InfoBox.tsx" /* webpackChunkName: "component---src-pages-cennik-components-info-box-tsx" */),
  "component---src-pages-cennik-components-info-item-tsx": () => import("./../../../src/pages/cennik/components/InfoItem.tsx" /* webpackChunkName: "component---src-pages-cennik-components-info-item-tsx" */),
  "component---src-pages-cennik-index-tsx": () => import("./../../../src/pages/cennik/index.tsx" /* webpackChunkName: "component---src-pages-cennik-index-tsx" */),
  "component---src-pages-home-page-components-info-box-tsx": () => import("./../../../src/pages/homePage/components/InfoBox.tsx" /* webpackChunkName: "component---src-pages-home-page-components-info-box-tsx" */),
  "component---src-pages-home-page-components-info-boxes-tsx": () => import("./../../../src/pages/homePage/components/InfoBoxes.tsx" /* webpackChunkName: "component---src-pages-home-page-components-info-boxes-tsx" */),
  "component---src-pages-home-page-components-item-list-tsx": () => import("./../../../src/pages/homePage/components/ItemList.tsx" /* webpackChunkName: "component---src-pages-home-page-components-item-list-tsx" */),
  "component---src-pages-home-page-components-offer-button-tsx": () => import("./../../../src/pages/homePage/components/OfferButton.tsx" /* webpackChunkName: "component---src-pages-home-page-components-offer-button-tsx" */),
  "component---src-pages-home-page-home-page-tsx": () => import("./../../../src/pages/homePage/HomePage.tsx" /* webpackChunkName: "component---src-pages-home-page-home-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kadra-components-kadra-item-tsx": () => import("./../../../src/pages/kadra/Components/KadraItem.tsx" /* webpackChunkName: "component---src-pages-kadra-components-kadra-item-tsx" */),
  "component---src-pages-kadra-index-tsx": () => import("./../../../src/pages/kadra/index.tsx" /* webpackChunkName: "component---src-pages-kadra-index-tsx" */),
  "component---src-pages-kadra-kadra-tsx": () => import("./../../../src/pages/kadra/Kadra.tsx" /* webpackChunkName: "component---src-pages-kadra-kadra-tsx" */),
  "component---src-pages-oferta-index-tsx": () => import("./../../../src/pages/oferta/index.tsx" /* webpackChunkName: "component---src-pages-oferta-index-tsx" */),
  "component---src-pages-oferta-oferta-tsx": () => import("./../../../src/pages/oferta/Oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-oferta-tsx" */),
  "component---src-pages-polityka-index-tsx": () => import("./../../../src/pages/polityka/index.tsx" /* webpackChunkName: "component---src-pages-polityka-index-tsx" */),
  "component---src-pages-polityka-polityka-tsx": () => import("./../../../src/pages/polityka/Polityka.tsx" /* webpackChunkName: "component---src-pages-polityka-polityka-tsx" */),
  "component---src-pages-strefa-kursanta-index-tsx": () => import("./../../../src/pages/strefa-kursanta/index.tsx" /* webpackChunkName: "component---src-pages-strefa-kursanta-index-tsx" */),
  "component---src-pages-strefa-kursanta-strefa-kursanta-tsx": () => import("./../../../src/pages/strefa-kursanta/StrefaKursanta.tsx" /* webpackChunkName: "component---src-pages-strefa-kursanta-strefa-kursanta-tsx" */)
}

