import { DefaultTheme } from "styled-components"

export const mediaQueries = {
  sm: 680,
  md: 1000,
  xl: 1310,
  lg: 1440,
}

declare module "styled-components" {
  export interface DefaultTheme {
    breakpoints: {
      up: (screen: breakpointsType) => string
      down: (screen: breakpointsType) => string
    }
    colors: {
      [color: string]: string
    }
  }
}

export type breakpointsType = keyof typeof mediaQueries

export const theme: DefaultTheme = {
  breakpoints: {
    up: (screen: breakpointsType) => {
      return `@media (min-width:${mediaQueries[screen]}px)`
    },
    down: (screen: breakpointsType) => {
      return `@media (max-width:${mediaQueries[screen]}px)`
    },
  },
  colors: {
    primary: "#00008D",
    secoundary: "#7CB3C5",
    neutralDark: "#3F3D56",
    neutralText: "#A0A0A0",
  },
}
